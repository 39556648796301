@import url(https://fonts.googleapis.com/css2?family=Michroma&family=Nunito:ital,wght@0,400;0,700;1,400;1,700&family=Space+Mono&display=swap&family=Quattrocento:wght@700&display=swapay=swap&family=Montserrat:wght@600&display=swap&https://fonts.googleapis.com/css2?family=Spectral+SC:wght@500&display=swap);
html {
  scroll-behavior: smooth;
}
.App {
  font-family: "Spectral SC", serif;

  overflow-x: hidden;
}
.head-image {
  padding-top: 100px;
}
.mycontainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}
p,
h1,
h2,
h3,
h4,
h5,
a {
  margin: 0;
}

a,
button,
p {
  font-size: 1.125rem;
}
h1,
h2,
h3 {
  font-size: 3rem;
  font-family: "Spectral SC", serif;
  color: #0b152d;
}
h1 {
  line-height: 5.5rem;
}
h2 {
  font-size: 4rem;
}
h3 {
  font-size: 2.25rem;
}
h4 {
  font-size: 1.5rem;
}
@media only screen and (max-width: 768px) {
  a,
  button,
  p {
    font-size: 1rem;
  }
  h1,
  h2,
  h3 {
    font-size: 2.5rem;
    font-family: "Montserrat", sans-serif;
  }
  h1 {
    line-height: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.85rem;
  }
  h4 {
    font-size: 1.25rem;
  }
}
.header-navbar .nav-link {
  text-transform: uppercase;
  font-family: "Spectral SC", serif;
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

